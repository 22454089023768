import React from 'react'
import { useTheme, Box, Typography, CardMedia, useMediaQuery } from '@mui/material'
import OutlinedButton from '../../components/atoms/outlinedButton'
import ContainedButton from '../../components/atoms/containedButton'

const JoinUs = () => {
	const theme = useTheme()
	const isNotTablet = useMediaQuery('(min-width:1100px)')
	const isNotMobile = useMediaQuery('(min-width:800px)')

	return (
		<Box py={isNotTablet ? 6 : 3} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
			<Box p={4} sx={{ maxWidth: '1120px', width: '100%' }}>
				<Typography variant="h2">Convainvu ?</Typography>
				<Typography variant="h5">Rejoignez-nous maintenant !</Typography>
				<Box display="flex" alignItems="center" flexDirection={isNotMobile ? 'row' : 'column'}>
					<Box
						flex={1}
						py={6}
						display="flex"
						gap="1rem"
						flexDirection={isNotTablet ? 'row' : 'column'}
						justifyContent={isNotTablet ? 'flex-start' : 'center'}
						sx={{ zIndex: 10 }}
					>
						<OutlinedButton href="mailto:bonjour@pourliche.fr" text="Contactez-nous" />
						<ContainedButton
							href="https://shop.pourliche.fr/"
							target="_blank"
							text="Accéder au store"
							color={theme.palette.primary.blue}
						/>
					</Box>
					<Box
						flex={1}
						mr={4}
						display="flex"
						justifyContent="flex-end"
						alignItems="center"
						sx={{
							order: isNotTablet ? 2 : 1,
							position: 'relative',
							minWidth: '330px',
							minHeight: '300px',
							width: isNotTablet ? '' : '300px',
							alignSelf: isNotTablet ? '' : 'center',
						}}
					>
						<CardMedia
							component="img"
							image={'/images/join-us-black-card.png'}
							alt="Carte noire Pourliche"
							height={isNotTablet ? 247 : 200}
							width={isNotTablet ? 328 : 275}
							sx={{
								maxWidth: isNotTablet ? 328 : 275,
								zIndex: 2,
								position: 'absolute',
								left: isNotTablet ? '.5rem' : '-1.5rem',
								marginBottom: '9rem',
							}}
						/>
						<CardMedia
							component="img"
							image={'/images/join-us-white-card.png'}
							alt="Carte blanche Pourliche"
							height={isNotTablet ? 190 : 180}
							width={isNotTablet ? 295 : 260}
							sx={{
								maxWidth: isNotTablet ? 295 : 260,
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default JoinUs
