import React from 'react'
import { useTheme, Box, Typography, CardMedia, useMediaQuery } from '@mui/material'

const EasyAndIntuitive = () => {
	const theme = useTheme()
	const isNotTablet = useMediaQuery('(min-width:1180px)')
	const isNotMobile = useMediaQuery('(min-width:800px)')
	const isNotSmallMobile = useMediaQuery('(min-width:500px)')

	return (
		<Box
			id="easyAndIntuitive"
			mb={4}
			sx={{
				position: 'relative',
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				minHeight: '490px',
				border: '1px solid rgba(106, 106, 106, 0.5)',
				background: 'linear-gradient(170.24deg, #7C74FF 4.37%, #7C74FF 45.55%, rgba(124, 116, 255, 0.83) 92.66%)',
			}}
		>
			<Box
				position="absolute"
				sx={{
					width: '100%',
					height: '100%',
					backgroundSize: 'cover',
					backgroundImage: 'url(/images/waves.png)',
				}}
			></Box>
			<Box
				mx={4}
				display="flex"
				flexDirection={isNotMobile ? 'row' : 'column'}
				gap="1rem"
				position="relative"
				sx={{
					width: '100%',
				}}
			>
				{isNotMobile ? (
					<Box flex={1.2} position="relative" sx={{ overflow: 'hidden', display: isNotTablet ? 'inherit' : 'none' }}>
						<CardMedia
							component="img"
							image={'/images/easy-and-intuitive-1-desktop.png'}
							alt="Facile et intuitif"
							height="auto"
							sx={{
								position: 'absolute',
								width: '28vw',
								minWidth: '400px',
								maxWidth: '100%',
								zIndex: 2,
								bottom: '-9rem',
								height: '590px',
								left: 0,
							}}
						/>
					</Box>
				) : (
					<Box display="flex" sx={{ minHeight: '350px' }}>
						<Box ms={2} py={2} flex={1} display="flex" flexDirection="column" justifyContent="center" gap="8px">
							<Typography variant="h1" lineHeight={1} letterSpacing="1px" color={theme.palette.primary.white}>
								Facile
							</Typography>
							<Typography variant="h2" lineHeight={1.25} color={theme.palette.primary.white}>
								et
							</Typography>
							<Typography variant="h1" lineHeight={1} letterSpacing="1px" color={theme.palette.primary.white}>
								intuitif
							</Typography>
						</Box>
						<Box flex={1.2} position="relative">
							<CardMedia
								component="img"
								image={'/images/easy-and-intuitive-1-mobile.png'}
								alt="Facile et intuitif"
								sx={{
									position: 'absolute',
									width: '100%',
									maxWidth: isNotSmallMobile ? '280px' : '235px',
									// maxWidth: '100%',
									zIndex: 8,
									top: '-3rem',
									height: isNotSmallMobile ? '457px' : '440px',
									right: isNotSmallMobile ? 'auto' : '-2rem',
								}}
							/>
						</Box>
					</Box>
				)}

				{isNotMobile ? (
					<Box flex={1} display="flex" gap="2rem" flexDirection="column" justifyContent="center" alignItems="center">
						<Typography variant="h1" color={theme.palette.primary.white}>
							Facile et intuitif, pour vous et vos clients
						</Typography>
						<CardMedia
							component="img"
							image={'/images/join-us-white-card.png'}
							alt="Carte blanche Pourliche"
							height={183}
							width={284}
							sx={{
								maxWidth: '284px',
							}}
						/>
					</Box>
				) : (
					<Box my={2} py={2} flex={1} display="flex" justifyContent="center" gap="8px">
						<Typography variant="h2" lineHeight={1.25} color={theme.palette.primary.white}>
							pour
						</Typography>
						<Typography variant="h1" lineHeight={1} letterSpacing="1px" color={theme.palette.primary.white}>
							vous
							<br /> et vos clients !
						</Typography>
					</Box>
				)}
				<Box
					flex={1.5}
					position="relative"
					sx={{ overflow: isNotMobile ? 'hidden' : 'visible', minHeight: isNotMobile ? 'auto' : '370px' }}
				>
					<CardMedia
						component="img"
						image={'/images/easy-and-intuitive-2-desktop.png'}
						alt="Facile et intuitif"
						height="auto"
						sx={{
							position: 'absolute',
							width: '20vw',
							minWidth: isNotMobile ? '260px' : '200px',
							maxWidth: '100%',
							zIndex: 2,
							top: isNotMobile ? 'auto' : '1rem',
							bottom: isNotMobile ? '-8rem' : 'auto',
							height: isNotMobile ? '540px' : '330px',
							left: isNotMobile ? 0 : 'auto',
							right: isNotMobile ? 'auto' : 0,
							transform: isNotMobile ? '' : 'rotate(6deg)',
						}}
					/>
					<CardMedia
						component="img"
						image={'/images/easy-and-intuitive-3-desktop.png'}
						alt="Facile et intuitif"
						height="auto"
						sx={{
							position: 'absolute',
							width: '20vw',
							minWidth: isNotMobile ? '335px' : '260px',
							maxWidth: '100%',
							zIndex: 1,
							top: isNotMobile ? 'auto' : 0,
							bottom: isNotMobile ? '-11rem' : 'auto',
							height: isNotMobile ? '610px' : '380px',
							right: isNotMobile ? 0 : 'auto',
							left: isNotMobile ? 'auto' : 0,
							transform: isNotMobile ? '' : 'rotate(-26deg)',
						}}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export default EasyAndIntuitive
