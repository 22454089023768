import React from 'react'
import { useTheme, Box, Typography, CardMedia, useMediaQuery } from '@mui/material'

const BenefitsCard = ({ img, title, text }) => {
	const theme = useTheme()
	const isNotTablet = useMediaQuery('(min-width:1100px)')

	return (
		<Box
			px={2}
			py={4}
			display="flex"
			flexDirection="column"
			alignItems="center"
			sx={{
				width: '100%',
				maxWidth: '500px',
				minHeight: isNotTablet ? '500px' : 'auto',
				backgroundColor: isNotTablet ? theme.palette.primary.white : 'transparent',
				borderRadius: '18px',
				border: isNotTablet ? '1px solid RGBA(37,37,37,.5)' : '',
				boxShadow: isNotTablet ? '0px 4px 20px 0px #0000001A' : '',
			}}
		>
			<CardMedia
				component="img"
				image={img}
				alt={title}
				height="auto"
				width="100%"
				sx={{
					marginBottom: '2rem',
					maxHeight: '210px',
					maxWidth: '250px',
				}}
			/>
			<Box px={2} sx={{ textAlign: isNotTablet ? 'start' : 'center' }}>
				<Typography variant="h3">{title}</Typography>
				<Typography variant="p" sx={{ maxWidth: '100px' }}>
					{text}
				</Typography>
			</Box>
		</Box>
	)
}

export default BenefitsCard
