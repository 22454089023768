// mui theme settings
export const themeSettings = (mode: any) => {
	return {
		palette: {
			primary: {
				main: '#1565c0',
				black: '#252525',
				grey: '#5E6264',
				lightGrey: '#F6F6F6',
				white: '#ffffff',
				lightRed: '#FFF0F0',
				red: '#E30613',
				lightBlue: '#F6F5FF',
				blue: '#009FE3',
				yellow: '#FFED00',
				warning: '#ED6C02',
			},
		},
		typography: {
			h1: {
				fontFamily: ['Figtree', 'Arial', 'sans-serif'].join(','),
				fontSize: 'clamp(32px,3vw,36px)',
				fontWeight: 900,
				lineHeight: 1.5,
				color: '#252525',
			},
			h2: {
				fontFamily: ['Figtree', 'Arial', 'sans-serif'].join(','),
				fontSize: 'clamp(24px,3vw,32px)',
				fontWeight: 900,
				lineHeight: 1.5,
				color: '#252525',
			},
			h3: {
				fontFamily: ['Figtree', 'Arial', 'sans-serif'].join(','),
				fontSize: '24px',
				fontWeight: 700,
				lineHeight: 1.5,
				color: '#252525',
				marginBottom: '1rem',
			},
			label: {
				fontFamily: ['Figtree', 'Arial', 'sans-serif'].join(','),
				fontSize: '20px',
				fontWeight: 900,
				color: '#252525',
			},
			p: {
				fontFamily: ['Figtree', 'Arial', 'sans-serif'].join(','),
				fontSize: '20px',
				fontWeight: 500,
				color: '#5E6264',
				lineHeight: 1.5,
			},
			smallButton: {
				fontFamily: ['Figtree', 'Arial', 'sans-serif'].join(','),
				fontSize: '20px',
				fontWeight: 600,
				lineHeight: 1.5,
				textTransform: 'none',
				whiteSpace: 'nowrap',
			},
			button: {
				fontFamily: ['Figtree', 'Arial', 'sans-serif'].join(','),
				fontSize: '20px',
				fontWeight: 700,
				lineHeight: 1.5,
				textTransform: 'none',
				whiteSpace: 'nowrap',
			},
		},
	}
}
