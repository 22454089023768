import React from 'react'
import { useTheme, Button, Link, Typography, useMediaQuery } from '@mui/material'

const OutlinedButton = ({ href, text, color, secondary }) => {
	const theme = useTheme()
	const isNotSmallMobile = useMediaQuery('(min-width:500px)')

	return (
		<Button
			variant="outlined"
			sx={{
				height: secondary ? '42px' : isNotSmallMobile ? '62px' : '46px',
				color: color ? color : theme.palette.primary.black,
				borderWidth: '2px',
				borderColor: color ? color : theme.palette.primary.black,
				padding: '0 2rem',
				minWidth: '180px',
				maxWidth: '260px',
				borderRadius: '6px',
				'&:hover': { border: `2px solid ${color ? color : theme.palette.primary.black}` },
			}}
		>
			<Link href={href} color={color ? color : theme.palette.primary.black}>
				<Typography variant="button">{text}</Typography>
			</Link>
		</Button>
	)
}

export default OutlinedButton
