import React from 'react'
import { CardMedia, useTheme, Box, Typography, useMediaQuery } from '@mui/material'

const Hero = (scan) => {
	scan = scan.scan
	const theme = useTheme()
	const isNotTablet = useMediaQuery('(min-width:1100px)')
	const isNotMobile = useMediaQuery('(min-width:600px)')

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'left',
				width: isNotTablet ? '100%':'100%',
				margin: '0 auto',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: isNotTablet ? 'row' : 'column',
					gap: isNotTablet ? '' : '5rem',
					alignItems: 'center',
				}}
			>
				<Box
					flex="1"
					display="flex"
					flexDirection="column" 
					gap="0.7rem"
					mt={3}
					sx={{ 
						order: isNotTablet ? 1 : 2,
						width: isNotTablet ? '450px' : '100%',
						position: 'relative',
						pt: isNotTablet ? '1rem' : '4rem',
						minHeight: isNotTablet ? 'calc(60vh - (85px + 2rem))':'calc(50vh - (85px + 2rem))',
					}}
				>
					<CardMedia
						component="img"
						image={'/images/cerf-volant_001.svg'}
						alt="Carte noire Pourliche"
						height={isNotTablet ? 457 : 250}
						width="auto"
						sx={{
							zIndex: -2,
							position: 'absolute',
							right: isNotTablet ? '-6rem' : '-7.5rem',
							top: isNotTablet ? '-3rem' : '-1rem',
							marginBottom: '9rem',
							objectFit: 'contain'
						}}
					/>
					<Typography variant="p" sx={{ fontSize: 'clamp(18px,2vw,20px)', lineHeight: 0 }}>
						Laissez un pourboire à 
					</Typography>

					<Typography variant="h1" sx={{ mt:0, mb: -1.5 }}>
						{scan.name}
					</Typography>

					{(scan.occupation && scan.occupation.trim() !== '') &&
					<Typography variant="p" uppercase={false ? scan.occupation : undefined} sx={{ fontSize: 'clamp(18px,2vw,20px)', lineHeight: 0.5 }}>
						votre {scan.occupation + ' '} 
						{(scan.occupation && scan.occupation.trim() !== '' && scan.company && scan.company.trim() !== '') &&
						<Typography variant="p">
						de {scan.company}
						</Typography>
						}
					</Typography>
					}
				</Box>
			</Box>
		</Box>
	)
}

export default Hero
