import * as React from 'react'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

export default function breadcrumb({ parent, parentUrl, child }) {
	return (
		<Container component="main" maxWidth="lg">
			<Box sx={{ mt: 10, mb: 4 }}>
				<Breadcrumbs separator="›" aria-label="breadcrumb">
					<Link href="/" key="1">
						Accueil
					</Link>
					{parentUrl && (
						<Link href={parentUrl} key="2">
							{parent}
						</Link>
					)}
					{child && (
						<Typography color="text.primary" key="3">
							{child}
						</Typography>
					)}
				</Breadcrumbs>
			</Box>
		</Container>
	)
}
