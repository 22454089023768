import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme, useMediaQuery, Box, Typography, Link } from '@mui/material'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout';
import UserContext from '../context/userContext'
import Header from '../components/organisms/header'
import Footer from '../components/organisms/footer'
import Breadcrumb from '../components/Breadcrumb'
import MyCard from './settings/myCard'
import MyInfo from './settings/myInfo'

const Tab = ({ title, icon, currentTab, setCurrentTab, tabId }) => {
	const theme = useTheme()
	const isNotMobile = useMediaQuery('(min-width:600px)')

	return (
		<Box
			display="flex"
			alignItems="center"
			gap=".5rem"
			px={2}
			sx={{
				height: '46px',
				backgroundColor: currentTab === tabId ? theme.palette.primary.lightBlue : 'inherit',
				borderRadius: '4px',
				border: `1px solid rgba(94, 98, 100, 0.4)`,
				cursor: 'pointer',
				borderBottomRightRadius: !isNotMobile && tabId === 0 ? '0px' : '4px',
				borderTopRightRadius: !isNotMobile && tabId === 0 ? '0px' : '4px',
				borderTopLeftRadius: !isNotMobile && tabId === 1 ? '0px' : '4px',
				borderBottomLeftRadius: !isNotMobile && tabId === 1 ? '0px' : '4px',
				borderRight: !isNotMobile && tabId === 0 ? 'none' : `1px solid rgba(94, 98, 100, 0.4)`,
				borderLeft: !isNotMobile && tabId === 1 ? 'none' : `1px solid rgba(94, 98, 100, 0.4)`,
			}}
			onClick={() => setCurrentTab(tabId)}
		>
			{icon}
			<Typography
				variant="p"
				sx={{
					fontWeight: 600,
					fontSize: '16px',
					color: currentTab === tabId ? theme.palette.primary.black : theme.palette.primary.grey,
				}}
			>
				{title}
			</Typography>
		</Box>
	)
}

const Settings = () => {
	const theme = useTheme()
	const navigate = useNavigate()
	const isNotMobile = useMediaQuery('(min-width:600px)')
	const { user, card } = useContext(UserContext)
	const [currentTab, setCurrentTab] = useState(0)

	useEffect(() => {
		if (!localStorage.getItem('token')) {
			navigate('/logout')
		}
	}, [navigate])

	return (
		<Box py={4} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="space-between"
				sx={{ width: '100%', maxWidth: '1120px' }}
			>
				<Header
					logo="black"
					backgroundColor={theme.palette.primary.white}
					linksValue={Object.keys(card).length === 0}
				/>
				<Breadcrumb parent="" parentUrl="" child="Paramètres du compte" />

				<Box px={2} sx={{ width: '100%',  position: 'relative' }}>
					<Typography variant="h1">
						<Box component="span" sx={{ fontWeight: 700 }}>
							Bonjour
						</Box>{' '}
						{(user && user.name) || 'toi'}
					</Typography>
					<Link href="/logout" 
						sx={{
							mt: 0, 
							pb: 4, 
							position: 'absolute', 
							right: '1rem', 
							top: '1rem',
							fontSize: '.75rem'
						}}
					>
						<LogoutIcon sx={{fontSize: '16px'}}/>
					</Link>
					<Box
						display={isNotMobile ? 'flex' : 'grid'}
						gap={isNotMobile ? '1rem' : 0}
						sx={{ gridTemplateColumns: '1fr 1.5fr' }}
					>
						<Tab
							title="Ma carte"
							icon={
								<CreditCardRoundedIcon
									fontSize="small"
									sx={{ fill: currentTab === 0 ? theme.palette.primary.black : theme.palette.primary.grey }}
								/>
							}
							currentTab={currentTab}
							setCurrentTab={setCurrentTab}
							tabId={0}
						/>
						<Tab
							title="Mes informations"
							icon={
								<AccountCircleIcon
									fontSize="small"
									sx={{ fill: currentTab === 1 ? theme.palette.primary.black : theme.palette.primary.grey }}
								/>
							}
							currentTab={currentTab}
							setCurrentTab={setCurrentTab}
							tabId={1}
						/>
					</Box>
					{currentTab === 0 ? <MyCard /> : <MyInfo />}
				</Box>
				<Footer />
			</Box>
		</Box>
	)
}

export default Settings
