import React from 'react'
import { Box } from '@mui/material'

import Header from '../components/organisms/header'
import Footer from '../components/organisms/footer'
import Hero from './home/hero'
import EasyLife from './home/easyLife'
import HowItWorks from './home/howItWorks'
import Benefits from './home/benefits'
import JoinUs from './home/joinUs'
import EasyAndIntuitive from './home/easyAndIntuitive'

const Home = () => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center" sx={{ overflowX: 'hidden' }}>
			<Header logo="black" backgroundColor="#fff" />
			<Hero />
			<EasyLife />
			<HowItWorks />
			<Benefits />
			<JoinUs />
			<EasyAndIntuitive />
			<Footer />
		</Box>
	)
}

export default Home
