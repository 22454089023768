import React, { useState, useEffect } from 'react'
import { Box, Container, Snackbar, IconButton, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'

import HeaderFaqDrawer from '../components/organisms/headerFaqDrawer'
import Footer from '../components/organisms/footer'
import RegisterPage from './register/registerPage'

const Tab = styled(Box)`
	color: #000;
	cursor: pointer;
	font-size: 0.875rem;
	font-weight: 600;
	background-color: transparent;
	width: 100%;
	padding: 10px 12px;
	margin: 6px 6px;
	border: 3px solid transparent;
	border-radius: 7px;
	display: flex;
	justify-content: center;

	&:hover {
		background-color: rgb(255, 242, 0, 0.4);
	}

	&:focus {
		color: #000;
		border: 3px solid #ed1c24;
	}
`

const TabsList = styled(Box)(
	({ theme }) => `
	width: 100%;
	// background-color: #ED1C24;
	border-radius: 12px;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: space-between;
	box-shadow: 0px 4px 30px lightgrey;
	`,
)

const Register = () => {
	const [emailCode, setEmailCode] = useState(0)
	const [openSnack, setOpenSnack] = useState(false)
	const [snackMessage, setSnackMessage] = useState('')
	const [ref, setRef] = useState(JSON.parse(localStorage.getItem('ref')))

	const handleCloseSnack = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setOpenSnack(false)
	}

	const action = (
		<div>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</div>
	)

	return (
		<>
			<HeaderFaqDrawer user="1" lang="fr" logo="black" backgroundColor="white" />
			<Container maxWidth="md"sx={{mt: 16}}>
				<Grid container sx={{mt: 2, mb: 4}}>
					<Grid item md={6} align="center" sx={{display: { md: 'block', xs: 'none' }}}>
						<img src="/images/undraw_voice_assistant_nrv7 1.svg" alt="Pourliche" style={{width:'100%'}}/>
						{/* <img
							src={`/media/products/` + ref + `.png`}
							width={'80%'}
							loading="lazy"
						/> */}
					</Grid>
					<RegisterPage></RegisterPage>
				</Grid>
			</Container>
			<Snackbar
				open={openSnack}
				autoHideDuration={4000}
				onClose={handleCloseSnack}
				message={snackMessage}
				action={action}
			/>
			<Footer />
		</>
	)
}

export default Register
