import React, { useState, useEffect, useContext } from 'react'
import {
	FormControlLabel,
	Checkbox,
	InputAdornment,
	IconButton,
	useTheme,
	useMediaQuery,
	Box,
	Typography,
	CardMedia,
	TextField,
	Link,
	Badge,
} from '@mui/material'
import UserContext from '../../context/userContext'
import ShareIcon from '@mui/icons-material/Share'
import ContainedButton from '../../components/atoms/containedButton'

const MyCard = () => {
	const theme = useTheme()
	const isNotTablet = useMediaQuery('(min-width:800px)')
	const { card, setCard, setOpenSnack, setSnackMessage } = useContext(UserContext)
	const [helperChangeUrl, setHelperChangeUrl] = useState('')
	const [helperChangeUrlErr, setHelperChangeUrlErr] = useState(false)
	const [infos, setInfos] = useState({
		id: null,
		url: '',
		ref: '',
		serial: '',
		date: null,
		url_warning: '',
		display_warning: 0,
	})

	useEffect(() => {
		if (Object.keys(card).length !== 0) {
			setInfos(card)
		}
	}, [card])

	async function cardRequest() {
		try {
			await fetch(process.env.REACT_APP_BASE_URL + '/ws/updateLinks', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', Authorization: `Bearer ` + localStorage.getItem('token') },
				body: JSON.stringify({
					id: card.id,
					url: infos.url,
					url_warning: infos.url_warning,
					display_warning: infos.display_warning,
				}),
			})
				.then(response => {
					if (response.ok) {
						return response.json()
					}
					throw new Error('error')
				})
				.then(data => {
					setOpenSnack(true)
					if (data && data.status) {
						console.log('ad')
						setCard(infos)
						setSnackMessage('Modifications de votre carte enregistrées')
					} else {
						setSnackMessage('Erreur lors de la mise à jour de votre carte')
					}
				})
		} catch (error) {
			console.log(error.message)
			setOpenSnack(true)
			setSnackMessage('Erreur lors de la mise à jour de votre carte')
		}
	}

	const handleSaveCard = e => {
		e.preventDefault()
		setOpenSnack(false)
		setHelperChangeUrlErr(false)
		setHelperChangeUrl(false)

		if(infos.url !== ''){
			if ( infos.url && !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/i.test(infos.url)) {
				setHelperChangeUrlErr(true)
				setHelperChangeUrl('Veuillez entrer une URL valide.')
				setOpenSnack(true)
				setSnackMessage('Veuillez entrer une URL valide')
				return
			}
		}
		cardRequest()
	}

	return (
		<>
			{Object.keys(card).length === 0 && (
				<Box mt={4} display="flex" gap="1rem">
					<Link href="/faq/lier-e-tirelire">
						<Badge badgeContent={'!'} color="error" sx={{ fontSize: 14, height: 20, width: 20 }} />
					</Link>
					<Typography mb={2} variant="p" sx={{ color: theme.palette.primary.red, fontSize: '12px' }}>
						Attention, tu n'as pas encore enregistré de cagnotte !
					</Typography>
				</Box>
			)}

			<Box
				mb={4}
				mt={Object.keys(card).length === 0 ? 0 : 4}
				display="grid"
				gridTemplateColumns={isNotTablet ? '1fr 2fr' : '1fr'}
				gap="2rem"
			>
				<Box align="right">
					<CardMedia component="img" image={`/images/products/` + card.ref + `.png`} alt="Carte pourliche" />
					<Link variant="small" href={'/' + card.serial || 'XXXXXXXXX'} target="_blank">
						<ShareIcon sx={{ fontSize: 15, mb: -0.3 }} /> Voir ma carte
					</Link>
				</Box>
				<Box component="form" noValidate onSubmit={handleSaveCard}>
					<TextField
						// type="password"
						value={card.serial || 'XXXXXXXXX'}
						name="serial"
						id="serial"
						label="N° de série de votre carte"
						placeholder="XXXXXXXXX"
						sx={{ width: 1, mt: 2, mb: 1 }}
						disabled
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton edge="end" href={'/' + card.serial || 'XXXXXXXXX'} target="_blank">
										<ShareIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<TextField
						value={infos.url}
						name="url"
						id="url"
						label="Lien vers votre tirelire électronique"
						placeholder="https://lienversmatirelire.fr/"
						onChange={e => setInfos({ ...infos, url: e.target.value })}
						// helperText="Votre tirelire électronique sera rattachée à votre carte"
						sx={{ width: 1, mt: 2, mb: 1 }}
						InputLabelProps={{ shrink: true }}
						error={helperChangeUrl && helperChangeUrlErr ? true : false}
						helperText={
							helperChangeUrl ? (
								helperChangeUrlErr ? (
									helperChangeUrl
								) : (
									<Typography variant="span" color={theme.palette.primary.warning}>
										{helperChangeUrl}
									</Typography>
								)
							) : undefined
						}
					/>

					<FormControlLabel
						control={<Checkbox />}
						label="Afficher la page de présentation"
						id="display_warning"
						name="display_warning"
						checked={infos.display_warning === 0 ? false : true}
						onChange={() => setInfos({ ...infos, display_warning: infos.display_warning === 0 ? 1 : 0 })}
					/>
					{infos.display_warning === 0 && (
						<Box>
							<Typography component="small" variant="p" fontSize={12} color="text.secondary">
								Votre client sera directement redirigé vers votre tirelire électronique. <br />
							</Typography>
						</Box>
					)}
					{infos.display_warning === 1 && (
						<>
							<Box mb={2}>
								<Typography variant="p" fontSize={12} color="text.secondary">
									La page de présentation s'affichera lorsque votre client scannera votre carte Pourliche. <br />
									Vous pourrez également écrire un message de remerciement à vos clients.
								</Typography>
							</Box>
							<TextField
								label="Message pour vos clients"
								id="url_warning"
								name="url_warning"
								value={infos.url_warning ? infos.url_warning : ''}
								onChange={e => setInfos({ ...infos, url_warning: e.target.value })}
								multiline
								rows={2}
								sx={{ width: 1, mt: 2, mb: 1 }}
								InputLabelProps={{ shrink: true }}
							/>
						</>
					)}
					<Box sx={{ width: 1, mt: 2, mb: 1 }} textAlign="end">
						<ContainedButton type="submit" text="Sauvegarder" secondary />
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default MyCard