import React, { useCallback, useState, useEffect, useContext } from 'react'
import {
	useTheme,
	useMediaQuery,
	Box,
	Typography,
	TextField,
	InputAdornment,
	IconButton,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from '@mui/material'
import HourglassFullIcon from '@mui/icons-material/HourglassFull'
import EditIcon from '@mui/icons-material/Edit'
import UserContext from '../../context/userContext'
import ContainedButton from '../../components/atoms/containedButton'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const MyInfo = () => {
	const theme = useTheme()
	const isNotTablet = useMediaQuery('(min-width:800px)')
	const [helperChangeEmail, setHelperChangeEmail] = useState('')
	const [helperChangeEmailErr, setHelperChangeEmailErr] = useState(false)
	const { user, setUser, setOpenSnack, setSnackMessage } = useContext(UserContext)
	const [infos, setInfos] = useState({
		name: '',
		email: '',
		password: '',
		phone: '',
		created_date: '',
		company: '',
		occupation: '',
		sector: '',
	})
	const [errorMsg, setErrorMsg] = useState('')
	const [errorFocusPhone, setErrorFocusPhone] = useState(false)

	useEffect(() => {
		if (Object.keys(user).length !== 0) {
			setInfos(user)
		}
	}, [user])

	const resetError = () => {
		setHelperChangeEmail('')
		setHelperChangeEmailErr(false)
		setErrorFocusPhone(false)
		setErrorMsg('')
	}

	const handleChange = event => {
		resetError()
		const name = event.target.name
		const value = event.target.value
		setInfos(values => ({ ...values, [name]: value }))
	}

	async function userRequest() {
		try {
			await fetch(process.env.REACT_APP_BASE_URL + '/ws/updateProfile', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', Authorization: `Bearer ` + localStorage.getItem('token') },
				body: JSON.stringify(infos),
			})
				.then(response => {
					if (response.ok) {
						return response.json()
					}
					throw new Error('error')
				})
				.then(data => {
					setHelperChangeEmailErr(false)
					setOpenSnack(true)
					if (data.status) {
						resetError()
						setUser(infos)
						localStorage.setItem('user', JSON.stringify(infos))
						setSnackMessage('Modifications du profil enregistrées')
						if (data.status === 2) {
							setSnackMessage('Veuillez confirmer votre nouvelle adresse email')
							setHelperChangeEmail('Veuillez confirmer votre nouvelle adresse email')
						}
					} else if (data.error === 'email already used') {
						setHelperChangeEmailErr(true)
						setHelperChangeEmail('Cette adresse email est déjà utilisée. Veuillez en choisir une autre')
						setSnackMessage('Cette adresse email est déjà utilisée. Veuillez en choisir une autre')
					} else {
						setSnackMessage('Erreur lors de la mise à jour')
					}
				})
		} catch (error) {
			console.log(error.message)
			setOpenSnack(true)
			setSnackMessage('Erreur serveur')
		}
	}

	const handleSaveUser = event => {
		event.preventDefault()
		setOpenSnack(false)
		setHelperChangeEmailErr(false)
		setHelperChangeEmail(false)
		
		if(infos.email == ''){
			infos.email = user.email
		}
		if (infos.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(infos.email)) {
			setHelperChangeEmailErr(true)
			setHelperChangeEmail('Email invalide.')
			return
		} else if (infos.phone && !isValidPhoneNumber(infos.phone)) {
			setErrorFocusPhone(true)
			setErrorMsg(`Format du numéro de téléphone non valide.`)
			return
		}
		userRequest()
	}

	return (
		<Box component="form" noValidate onSubmit={handleSaveUser} my={4}>
			<Box display="grid" gridTemplateColumns={isNotTablet ? '1fr 1fr' : '1fr'} gap="2rem">
				<Box>
					<Typography variant="h3">Mon profil</Typography>
					<TextField
						value={infos.name}
						required
						name="name"
						id="name"
						label="Prénom"
						fullWidth
						onChange={handleChange}
						InputLabelProps={{ shrink: true }}
						sx={{ width: 1, mt: 2, mb: 1 }}
					/>
					<TextField
						value={infos.lastname}
						required
						name="lastname"
						id="lastname"
						label="Nom"
						fullWidth
						onChange={handleChange}
						InputLabelProps={{ shrink: true }}
						sx={{ width: 1, mt: 2, mb: 1 }}
					/>
					<TextField
						value={infos.email}
						error={helperChangeEmail && helperChangeEmailErr ? true : false}
						required
						name="email"
						id="email"
						label="Email"
						autoComplete="username"
						fullWidth
						onChange={handleChange}
						InputLabelProps={{ shrink: true }}
						sx={{ width: 1, mt: 2, mb: 1 }}
						InputProps={{
							endAdornment: helperChangeEmail && !helperChangeEmailErr && (
								<InputAdornment position="end">
									<HourglassFullIcon sx={{ '&>path': { fill: theme.palette.primary.warning } }} fontSize="small" />
								</InputAdornment>
							),
						}}
						helperText={
							helperChangeEmail ? (
								helperChangeEmailErr ? (
									helperChangeEmail
								) : (
									<Typography variant="span" color={theme.palette.primary.warning}>
										{helperChangeEmail}
									</Typography>
								)
							) : undefined
						}
					/>
					<FormControl
						sx={{
							width: 1,
							mt: 2,
							mb: 1,
							'& > .PhoneInput': {
								padding: '16.5px 14px',
								height: '56px',
								borderRadius: '4px',
								border: errorFocusPhone && errorMsg ? `1px solid #d32f2f` : '1px solid rgba(0, 0, 0, 0.23)',
							},
							'& > .PhoneInput > input': {
								border: 'none',
							},
						}}
					>
						<InputLabel
							shrink={true}
							sx={{
								background: theme.palette.primary.white,
								padding: ' 0 0.5rem',
								color: errorFocusPhone && errorMsg ? `#d32f2f` : 'rgba(0, 0, 0, 0.6)',
							}}
						>
							Téléphone
						</InputLabel>
						<PhoneInput
							defaultCountry="FR"
							id="phone"
							name="phone"
							value={infos.phone}
							onChange={e => {
								setInfos({ ...infos, phone: e })
								resetError()
							}}
						/>
						{errorFocusPhone && errorMsg && (
							<Typography mt=".25rem" ml=".25rem" variant="p" fontSize=".75rem" color="#d32f2f">
								{errorMsg}
							</Typography>
						)}
					</FormControl>
					<TextField
						disabled
						type="password"
						value={infos.password || 'hello'}
						name="password"
						id="password"
						label="Mot de passe"
						fullWidth
						autoComplete="current-password"
						InputLabelProps={{ shrink: true }}
						sx={{ width: 1, mt: 2, mb: 1 }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton edge="end" href="/changer-mot-de-passe" target="__blank">
										<EditIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>

					<TextField
						disabled
						name="created_date"
						id="created_date"
						value={user.created_date}
						label="Inscrit depuis"
						fullWidth
						InputLabelProps={{ shrink: true }}
						sx={{ width: 1, mt: 2, mb: 1 }}
					/>
				</Box>
				<Box>
					<Typography variant="h3">Ma profession</Typography>
					<TextField
						value={infos.company}
						name="company"
						id="company"
						label="Nom société"
						fullWidth
						onChange={handleChange}
						InputLabelProps={{ shrink: true }}
						sx={{ width: 1, mt: 2, mb: 1 }}
					/>

					<FormControl sx={{ width: 1, mt: 2, mb: 1 }}>
						<InputLabel shrink={true}>Secteur</InputLabel>
						<Select
							id="sector"
							name="sector"
							label="Secteur"
							notched={true}
							value={infos.sector ? infos.sector : 'Autres'}
							onChange={handleChange}
						>
							<MenuItem value="Beauté/Bien-être">Beauté/Bien-être</MenuItem>
							<MenuItem value="Ventes">Ventes</MenuItem>
							<MenuItem value="Hôtellerie/Restauration">Hôtellerie/Restauration</MenuItem>
							<MenuItem value="Autres">Autres</MenuItem>
						</Select>
					</FormControl>

					<TextField
						value={infos.occupation}
						name="occupation"
						id="occupation"
						label="Métier"
						fullWidth
						onChange={handleChange}
						InputLabelProps={{ shrink: true }}
						sx={{ width: 1, mt: 2, mb: 1 }}
					/>
				</Box>
			</Box>
			<Box sx={{ width: 1, mt: 2, mb: 1 }} textAlign="end">
				<ContainedButton type="submit" text="Sauvegarder" secondary />
			</Box>
		</Box>
	)
}

export default MyInfo
