import React from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { Button, useTheme, Box, Typography, useMediaQuery } from '@mui/material'

const Thanks = (scan) => {
	scan = scan.scan
	const isNotTablet = useMediaQuery('(min-width:1100px)')
	const isNotMobile = useMediaQuery('(min-width:600px)')
	
	let params = useParams()

	const noPiggyBankApiHandler = (event) => {
		event.currentTarget.disabled = true;
		alert('C\'est notifié !')
		event.preventDefault()
		noPiggyBankApi()
	}
	
	async function noPiggyBankApi() {
	fetch(process.env.REACT_APP_BASE_URL + '/ws/no-piggy-bank/' + params.g, { method: 'GET' })
		.then(data => data.json()) // Parsing the data into a JavaScript object
		.then(json => console.log(JSON.stringify(json))) // Displaying the stringified data in an alert popup
	}


	return (
		<Box
			sx={{
				maxWidth: '600px',
				justifyContent: 'left',
				margin: '0 auto',
			}}
		>
			<Box>
				<Typography variant="p" sx={{ fontSize: 'clamp(14px,2vw,14px)', mb: 5}}>
					{scan.name} vous a laissé un mot :
				</Typography>
				<Typography 
					component="p" 
					variant="p" 
					sx={{ 
						border:'dashed 1.5px #5E6264', 
						borderRadius: '5px',
						fontSize: 'clamp(14px,2vw,14px)', 
						mb: 5,
						mt: 2,
						p: 4,
					}}
				>
					{scan.url_warning}
				</Typography>
			</Box>
		</Box>
	)
}

export default Thanks
