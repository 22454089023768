import React from 'react'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { Route, Routes } from 'react-router-dom'
import { CssBaseline, ThemeProvider, createTheme, Theme, Snackbar, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { themeSettings } from './styles/theme'
import UserContext from './context/userContext'
import HomePage from './view/home'
import Login from './view/login'
import Logout from './view/logout'
import Settings from './view/settings'
import ChangePassword from './view/changePassword'
import Register from './view/register'
import ResetPassword from './view/resetPassword'
import Scan from './view/scan'
import Faq from './view/faq'
import FaqPage from './view/faqPage'
import Pages from './view/pages'

function App() {
	const [mode, setMode] = useState('light')
	const theme: Theme = useMemo(() => createTheme(themeSettings(mode)), [mode])
	const [user, setUser] = useState({})
	const [card, setCard] = useState({})
	const [openSnack, setOpenSnack] = useState(false)
	const [snackMessage, setSnackMessage] = useState('')
	const [loading, setLoading] = useState(false)

	const handleCloseSnack = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setOpenSnack(false)
	}

	const action = (
		<div>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</div>
	)

	const getUser = useCallback(async () => {
		try {
			await fetch(process.env.REACT_APP_BASE_URL + '/ws/user', {
				headers: {
					Authorization: `Bearer ` + localStorage.getItem('token'),
				},
			})
				.then(response => {
					if (response.ok) {
						return response.json()
					}
					throw new Error('error')
				})
				.then(data => {
					delete data.status['url']
					localStorage.setItem('user', JSON.stringify(data.status))
					setUser(data.status)
				})
		} catch (error) {
			console.log(error.message)
			setOpenSnack(true)
			setSnackMessage('Erreur serveur')
		}
		setLoading(false)
	}, [])

	const getCard = useCallback(async () => {
		try {
			await fetch(process.env.REACT_APP_BASE_URL + '/ws/links', {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
				.then(response => {
					if (response.ok) {
						return response.json()
					}
					throw new Error('error')
				})
				.then(data => {
					if (data.status) {
						localStorage.setItem('card', JSON.stringify(data.status))
						setCard(data.status)
					}
				})
		} catch (error) {
			console.log(error.message)
			setOpenSnack(true)
			setSnackMessage('Erreur serveur')
		}
		setLoading(false)
	}, [])

	useEffect(() => {
		if (localStorage.getItem('token')) {
			getUser()
		}
	}, [getUser])

	useEffect(() => {
		if (localStorage.getItem('token')) {
			getCard()
		}
	}, [getCard])

	return (
		<div className="App">
			<ThemeProvider theme={theme}>
				<UserContext.Provider
					value={{
						user,
						setUser,
						card,
						setCard,
						openSnack,
						setOpenSnack,
						snackMessage,
						setSnackMessage,
					}}
				>
					<CssBaseline />
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/register" element={<Register />} />
						<Route path="/register/:g" element={<Register />} />
						<Route path="/login" element={<Login />} />
						<Route path="/:g" element={<Scan />} />
						<Route path="/pages/:p" element={<Pages />} />
						<Route path="/faq">
							<Route index element={<Faq />} />
							<Route path=":q" element={<FaqPage />} />
						</Route>
						<Route path="/reset" element={<ResetPassword />} />
						<Route path="/settings" element={<Settings />} />
						<Route path="/changer-mot-de-passe" element={<ChangePassword />} />
						<Route path="/logout" element={<Logout />} />
					</Routes>
					<Snackbar
						open={openSnack}
						autoHideDuration={40000}
						onClose={handleCloseSnack}
						message={snackMessage}
						action={action}
					/>
				</UserContext.Provider>
			</ThemeProvider>
		</div>
	)
}

export default App
