import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import Header from '../components/organisms/header'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Footer from '../components/organisms/footer'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Stack from '@mui/material/Stack'

import {
	CssBaseline,
	Box,
	Link,
	Typography,
	Container,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@mui/material'

export default function Faq() {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	const breadcrumbs = [
		<Link underline="hover" key="1" color="inherit" href="/">
			Accueil
		</Link>,
	]

	useEffect(() => {
		getData()
	}, [])

	useEffect(() => {}, [data])

	const getData = useCallback(async () => {
		try {
			await fetch(process.env.REACT_APP_BASE_URL + '/ws/faq/?lang=fr', {})
				.then(response => {
					if (response.ok) {
						return response.json()
					}
					throw new Error('error')
				})
				.then(data => {
					setData(data)
				})
		} catch (error) {
			console.log(error.message)
		}
		setLoading(false)
	}, [])

	return (
		<ThemeProvider>
			<CssBaseline />
			<Header logo="black" backgroundColor="white" />
			<main>
				<Box
					sx={{
						pt: 12,
						pb: 6,
					}}
				>
					<Container maxWidth="lg">
						<Typography component="h1" variant="h2" color="text.primary" gutterBottom>
							Foire aux questions
						</Typography>
						<Stack spacing={2}>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								{breadcrumbs}
								<Typography key="2" color="text.primary">
									FAQ
								</Typography>
							</Breadcrumbs>
						</Stack>
					</Container>
				</Box>
				{/* <Breadcrumb child="FAQ"></Breadcrumb>
              Ici c'est la foire aux questions */}
				<Container sx={{ pt: 4, px: 2 }} maxWidth="md">
					{!loading && (
						<div>
							{data.map((comp, index) => (
								<Accordion key={index}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography>
											{comp.title}
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography sx={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: comp.content }} />
									</AccordionDetails>
								</Accordion>
							))}
						</div>
					)}
				</Container>
			</main>
			<Footer></Footer>
		</ThemeProvider>
	)
}
