import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useMediaQuery, Button, TextField, Link, Grid, Box, Typography, FormHelperText } from '@mui/material'
import RegisterConfirmEmail from './registerConfirmEmail'


const RegisterPage = () => {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [lastname, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
	const [currentTab, setEmailCode] = useState()
	const [errorEmail, setErrorEmail] = useState(false)
  const [helperText, setHelperText] = useState('');
	const isNotTablet = useMediaQuery('(min-width:1100px)')
	const isNotMobile = useMediaQuery('(min-width:600px)')


  const nameHandler = (event) => {
    setName(event.target.value)
  }

  const lastnameHandler = (event) => {
    setLastName(event.target.value)
  }
  
  const passwordHandler = (event) => {
    setPassword(event.target.value)
  }

  const emailHandler = (event) => {
    setEmail(event.target.value)
  }

  let params = useParams()

  useEffect(() => {
		if (params && !params.g) {
			void navigate('/')
		}
	}, [params, navigate])

  async function registerRequest() {
    try {
      await fetch(process.env.REACT_APP_BASE_URL + '/ws/register', {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin':'*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          lastname: lastname,
          password: password,
          email: email,
          serial: params.g,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          if(data.status !== 0){
            localStorage.setItem('token', data.status)
            localStorage.setItem('email', email)
            setEmailCode(1)
          }else if(data.status === 0) {
            if(data.error === 'serial already used') {
              navigate('/' + params.g)
            }else if(data.error === 'resent verification code') {
              setErrorEmail(true);
              const error = true 
              setHelperText('Un email de confirmation vous a été envoyé.');
            }else if(data.error === 'email already used') {
              setErrorEmail(true);
              const error = true 
              setHelperText('Cette adresse email est déjà utilisée');
            }else if(data.error === 'serial doesn\'t exist') {
              navigate('/')
            }
          }
        })
    } catch (error) {
      console.log(error.message)
    }
  }

  const submitHandler = (event) => {
    event.preventDefault()
    // reset error messages
    const error = false 
    setErrorEmail(false);
    setHelperText();

    registerRequest()
  }

  return (
    <Grid item md={6}>
      <Typography component="span" variant="h4" sx={{mb: 2, fontWeight: 'bold', fontSize: isNotTablet ? '2rem' : '2.5rem'}}>
        Félicitations pour votre achat !
      </Typography>
      <Typography component="p" variant="p" sx={{mb: 2, textAlign: 'justify'}}>
        Vous n'êtes plus qu'à quelques clics de vos pourboires par carte bancaire.
      </Typography>
      <Grid item md={6} align="center" sx={{display: { md: 'none', xs: 'block' }}}>
        <img src="/images/undraw_voice_assistant_nrv7 1.svg" alt="Pourliche" style={{width:'60%'}}/>
        {/* <img
          src={`/media/products/` + ref + `.png`}
          width={'80%'}
          loading="lazy"
        /> */}
      </Grid>
      <Box component="form" onSubmit={submitHandler} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="Prénom"
                autoFocus 
                onChange={nameHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
                required
                fullWidth
                id="lastName"
                label="Nom"
                name="lastName"
                autoComplete="family-name"
                onChange={lastnameHandler}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                error={errorEmail}
                required
                fullWidth
                id="email"
                label="Adresse Email"
                name="email"
                autoComplete="email"
                onChange={emailHandler}
            />
            <FormHelperText sx={{color:'#d32f2f'}}>{helperText}</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                fullWidth
                name="password"
                label="Mot de Passe"
                type="password"
                id="password"
                autoComplete="new-password"
                onChange={passwordHandler}
            />
          </Grid>
        </Grid>
        
        {currentTab !== 1 && (
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="info"
            sx={{ mt: 3, mb: 2, height: '56px'}} 
        >
            S'inscrire
        </Button>
        )}
        </Box>
        {currentTab === 1 && (<RegisterConfirmEmail></RegisterConfirmEmail>)}
    </Grid>
  );
}

export default RegisterPage