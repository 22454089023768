import React from 'react'
import { useTheme, Box, Typography, CardMedia, useMediaQuery } from '@mui/material'
import BenefitsCard from '../../components/molecules/benefitsCard'

const Benefits = () => {
	const theme = useTheme()
	const isNotTablet = useMediaQuery('(min-width:1100px)')

	return (
		<Box
			id="benefits"
			py={4}
			backgroundColor={isNotTablet ? theme.palette.primary.lightGrey : ''}
			sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
		>
			<Box
				mx={isNotTablet ? 4 : 2}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					maxWidth: '1120px',
				}}
			>
				<Box
					display="flex"
					alignItems={isNotTablet ? 'flex-end' : 'flex-start'}
					flexDirection={isNotTablet ? 'row' : 'column'}
					gap=".5rem"
				>
					<Box ml={2} display="flex" gap="1rem">
						<CardMedia
							component="img"
							image={'/icons/benefits-icon.svg'}
							alt="Benefits icon"
							width={34}
							height={40}
							sx={{ maxWidth: '34px' }}
						/>
						<Typography variant="h2" lineHeight={1.5} whiteSpace="nowrap">
							Les avantages
						</Typography>
					</Box>
					<CardMedia
						component="img"
						image={'/images/logo.png'}
						alt="Pourliche"
						width={223.5}
						height={108}
						sx={{ maxWidth: '223.5px', alignSelf: isNotTablet ? '' : 'center' }}
					/>
				</Box>
				<Box
					my={isNotTablet ? 6 : 3}
					display="flex"
					flexDirection={isNotTablet ? 'row' : 'column'}
					justifyContent="space-between"
					alignItems="center"
					gap={isNotTablet ? '2.5rem' : 0}
				>
					<BenefitsCard
						img={isNotTablet ? '/images/benefits-2.png' : '/images/benefits-2b.png'}
						title="Paiement unique"
						text={`Pourliche c'est l'assurance qu'aucun frais supplémentaire ne vous sera demandé que l'achat de votre carte.`}
					/>
					<BenefitsCard
						img={'/images/benefits-1.png'}
						title="Sans abonnement"
						text={`Votre carte Pourliche fonctionne sans abonnement. Par contre, vous serez abonnés aux pourboires par carte bancaire.`}
					/>
					<BenefitsCard
						img={'/images/benefits-3.png'}
						title="Pas de commission"
						text={`Aucune raison de partarger vos pourboires. Vous bénéficiez de la totalité de vos pourboires sans aucun frais supplémentaire.`}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export default Benefits
