import React, { useContext } from 'react'
import { useTheme, Box, Container } from '@mui/material'
import UserContext from '../context/userContext'
import Header from '../components/organisms/header'
import Footer from '../components/organisms/footer'
import Breadcrumb from '../components/Breadcrumb'
import ChangePassword from './reset/changePassword'

const ResetPassword = () => {
	const theme = useTheme()
	const { card } = useContext(UserContext)

	return (
		<Box py={4} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="space-between"
				sx={{ width: '100%', maxWidth: '1120px' }}
			>
				<Header
					logo="black"
					backgroundColor={theme.palette.primary.white}
					linksValue={Object.keys(card).length === 0}
				/>
				<Breadcrumb parent="Paramètres du compte" parentUrl="/settings" child="Modifier mon mot de passe" />
				<Box px={2} py={4} className="container">
					<ChangePassword />
				</Box>
				<Footer />
			</Box>
		</Box>
	)
}

export default ResetPassword
