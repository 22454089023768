import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container, FormHelperText } from '@mui/material'

const RegisterConfirmEmail = () => {
  const navigate = useNavigate()
  const [code, setCode] = useState('')
	const [errorCode, setErrorCode] = useState(false)
  const [helperText, setHelperText] = useState('');

  const codeHandler = (event) => {
    setCode(event.target.value)
  }

  async function confirmRequest() {
    try {
      await fetch(process.env.REACT_APP_BASE_URL + '/ws/confirm', {
        method: 'POST',
        body: JSON.stringify({
          code: code,
        }),
        headers: {
          'Access-Control-Allow-Origin':'*',
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          if (data.status === 1) {
            navigate('/login')
          } else {
            setErrorCode(true);
            const error = true 
            setHelperText('Ce code n\'est pas valide');
          }
        })
    } catch (error) {
      navigate('/')
    }
  }

  async function resendCodeRequest() {
    try {
      await fetch(process.env.REACT_APP_BASE_URL + '/ws/confirmCodeResend', {
        method: 'POST',
        body: JSON.stringify({
          email: localStorage.getItem('email'),
        }),
        headers: {
          'Access-Control-Allow-Origin':'*',
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          console.log(data);
        })
    } catch (error) {
      console.log(error);
      // navigate('/')
    }
  }

  const submitHandler = (event) => {
    event.preventDefault()
    confirmRequest()
  }

  return (
    <Grid item sx={{ mt: 3 }}>
      {/* <Typography component="p" variant="h4" sx={{marginBottom: 2}}>
        Confirmez votre email
      </Typography>
      <Typography component="p">
        Un email contenant le code de confirmation, vous a été envoyé. Copiez-le, afin de vérifier votre email. 
      </Typography>
      <Typography component="span" color="grey" sx={{fontStyle: 'italic', fontSize: '0.7rem'}}>
        Pensez à vérifier les spams/courriers indésirables
      </Typography> */}
      <Box component="form" onSubmit={submitHandler}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              error={errorCode}
              name="code"
              required
              fullWidth
              id="code"
              label="Code (reçu par email)"
              autoFocus 
              onChange={codeHandler}
            />
            <FormHelperText sx={{color:'#d32f2f'}}>{helperText}</FormHelperText>
            <FormHelperText 
              sx={{fontSize:'0.8rem',textTransform: 'initial'}} 
              onClick={() => resendCodeRequest()}>
            Recevoir un nouveau code
            </FormHelperText>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="info"
            >
              Finaliser l'inscription
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

export default RegisterConfirmEmail