import { createContext } from 'react'

const UserContext = createContext({
	user: {},
	setUser: function () {
		return
	},
	card: {},
	setCard: function () {
		return
	},
	openStack: false,
	setOpenSnack: function () {
		return
	},
	snackMessage: '',
	setSnackMessage: function () {
		return
	},
})

export default UserContext
